const Theme: any = {};

Theme.colors = {
  black: '#000000',
  green: '#D4F186',
  white: '#FFFFFF',
  grey900: '#191919',
  grey800: '#262628',
  grey700: '#2A2929',
  grey500: '#4C4C4C',
  grey200: '#A6A6A6',
  error: '#E56363',
};

Theme.typography = {
  monospaceFontFamily: '"IBM Plex Mono", monospace',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5rem',
  color: Theme.colors.white,
};

export { Theme };

export type ThemeType = typeof Theme;
