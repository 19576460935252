import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
  *, ::after, ::before {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-style: normal;
    font-stretch: normal;
    background-color: ${({ theme }) => theme.colors.grey800};
    font-family: ${({ theme }) => theme.typography.monospaceFontFamily};
    font-size: ${({ theme }) => theme.typography.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeight};
    color: ${({ theme }) => theme.typography.color};
    line-height: ${({ theme }) => theme.typography.lineHeight};
  }

  #___gatsby, #gatsby-focus-wrapper {
    all: inherit;
  }

  html {
    scroll-behavior: smooth;
  }

  ul, menu, dir {
    padding-left: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1, h2, h3, h4, p {
    margin: 0;
  }

  *:focus, *:active {
    outline: 0 !important;
  }

  a, button {
    transition: all 0.3s;
  }

  input, textarea {
    margin: 0;
    border: none;
  }

  button, input, textarea, select {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

export default Global;
